import * as React from 'react';
import './form-elements.scss';
import Select, { components } from 'react-select-v2';
import { Label } from 'reactstrap';

const { ValueContainer, Placeholder } = components;
const CustomValueContainer = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, child =>
        child && child.key !== 'placeholder' ? child : null
      )}
     {props.selectProps.value.label}
    </ValueContainer>
  );
};
const select = ({ options, labelKey, valueKey, label, input, id, ariaLabel, defaultOption, placeholder, searchable, meta: { touched, error } })  => {
  const customStyles = {
    container: base => ({
      ...base,
      width: '100%',
    }),
    control: (base, state) => ({
      ...base,
      fontSize: '1rem',
      height: '2.8125rem',
      letterSpacing: '0.03em',
      alignContent: 'center',
      verticalAlign: 'middle',
    }),
    valueContainer: (base, state) => ({
      ...base,
      fontSize: '1rem',
      marginLeft: '4px',
      overflow: 'visible',
      position: 'relative',
      top: '40%',
      transform: state.selectProps.value.value ? 'translateY(-25%)' : 'translateY(-50%)',
    }),
    input: (base, state) => ({
      ...base,
    }),
    placeholder: (base, state) => ({
      ...base,
      position: 'absolute',
      lineHeight: '1',
      pointerEvents: 'none',
      fontSize: state.selectProps.value.value  ? '.8rem' : '1rem',
      fontWeight: state.isFocused ? 'bold' : 'normal',
      top:  state.selectProps.value.value  ? -2.0 : '50%',
      transition: 'all 200ms cubic-bezier(0, 0.22, 0.12, 1) 0s',
      color: (touched && error) ? 'rgb(213, 0, 0)' : (state.selectProps.value.value  ? '#1d35f8' : 'rgb(105, 105, 105)'),
  
    }),
  };
  let valuefieldKey = valueKey ? valueKey : 'value';
  let labelfieldKey = labelKey ? labelKey : 'label';
  return (
    
    <div className="TextField__FieldWrapper">
        <div className={`select-ct ${touched && error && 'has-error'}`}>
          <Select 
                {...input}
                components={{ValueContainer: CustomValueContainer}}
                options={options}
                id={id}
                aria-owns={id}
                getOptionLabel={(option) => option[labelfieldKey]}
                getOptionValue={(option) => option[valuefieldKey]}
                onBlur={() => input.onBlur(input.value)}
                onFocus={() => input.onFocus(input.value)}
                isClearable={true}
                isSearchable={searchable ? searchable : false}
                aria-label={ariaLabel ? ariaLabel : label}
                aria-labeledby={id}
                classNamePrefix="Select"
                placeholder={placeholder ? placeholder : ''}
                styles={customStyles}
                controlShouldRenderValue={false}
                value={defaultOption ? {value : defaultOption[valuefieldKey], label : defaultOption[labelfieldKey]} : {value : undefined, label : undefined}}
            />
         </div>
         {ariaLabel !== '' && <Label className="hidden" aria-label={ariaLabel}></Label>}
        {touched && error && 
          // tslint:disable-next-line:max-line-length
         <div className="FieldError__ErrorContainer"><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12" y2="16"></line></svg> {error}</div>
        }
    </div>
  );
};

export const SimplerSelectV2 = select;